import { hashString } from "functions";
import QRCodeParser from "qrcode";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

const Canvas = styled.canvas`
  display: block;
  width: ${({ width }) => width}mm;
  height: ${({ width }) => width}mm;
`;

const Data = ({ data, width }) => {
  const className = useMemo(() => hashString(data + width), [data, width]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const canvases = document.getElementsByClassName(className);

    Array.from(canvases).forEach((canvas) => {
      QRCodeParser.toCanvas(canvas, data, {
        margin: 0,
        width: width * 3.7795275595,
      });
    });
  }, [data, width, className]);

  return <Canvas {...{ className, width }} />;
};

export default Data;
