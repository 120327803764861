import checkinBadgeTemplate from "./checkin-badge";
import checkinCardTemplate from "./checkin-card";
import checkinQrTemplate from "./checkin-qr";

const templates = [
  checkinBadgeTemplate,
  checkinCardTemplate,
  checkinQrTemplate,
];

export default templates;
