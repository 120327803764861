import brandColors from "./brand-colors";

const pxToMmRatio = 0.2645833333;

const theme = {
  space: {
    tiny: 2,
    mini: 4,
    small: 8,
    medium: 12,
    large: 16,
    huge: 22,
    giant: 28,
    mm: {
      tiny: 2 * pxToMmRatio,
      mini: 4 * pxToMmRatio,
      small: 8 * pxToMmRatio,
      medium: 12 * pxToMmRatio,
      large: 16 * pxToMmRatio,
      huge: 22 * pxToMmRatio,
      giant: 28 * pxToMmRatio,
    },
  },
  color: {
    ...brandColors,
    brandColors,
    text: {
      primary: brandColors.black,
      muted: brandColors.midGray,
      white: brandColors.white,
    },
    border: {
      primary: brandColors.softGray,
      muted: brandColors.dirtyWhite,
    },
    icon: {
      black: brandColors.black,
      muted: brandColors.lightGray,
      white: brandColors.white,
    },
  },
  typography: {
    heading: `"Peak", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`,
    text: `"RedHatText", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  },
};

export default theme;
