import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
	@font-face {
		font-family: "Peak";
		src: url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/600198d98dd135d347e15071_Peak-Semibold.woff2")
				format("woff2"),
			url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/600198d90f079a7f3addd81a_Peak-Semibold.woff")
				format("woff"),
			url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/600197ffc5bbb7a31557c600_Peak-Semibold.ttf")
				format("truetype"),
			url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/600198d90b2631aafeed4084_Peak-Semibold.otf")
				format("opentype");
		font-weight: 600;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "RedHatText";
		src: url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/5ff98f0ecd25785d1184e814_RedHatText-Regular.ttf")
			format("truetype");
		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "RedHatText";
		src: url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/5ff9a07279a885525b38ae5e_RedHatText-Bold.ttf")
			format("truetype");
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "RedHatText";
		src: url("https://uploads-ssl.webflow.com/5d8b58373d7d181ab20ac35d/5ff9a073ec24441088579375_RedHatText-Medium.ttf")
			format("truetype");
		font-weight: 500;
		font-style: normal;
		font-display: swap;
	}

	html {
		height: 100%;
		width: 100%;
	}
	
	body {
		margin: 0;
		height: 100%;
		width: 100%;
	}

	#root {
		height: 100%;
		width: 100%;
	}
`;
