import Papa from "papaparse";
import { compose, drop, map, prop, sortBy } from "ramda";
import React, { useCallback } from "react";
import styled from "styled-components";
import { checkinArrayToModel } from "../../functions";
import CoreUploadButton from "../UploadButton";

const StyledCoreUploadButton = styled(CoreUploadButton)`
  margin-bottom: ${(p) => p.theme.space.small}px;
`;

const UploadButton = ({ onChange }) => {
  const handleChange = useCallback(
    (str) =>
      compose(
        onChange,
        sortBy(prop("id")),
        map(checkinArrayToModel),
        drop(1),
        prop("data"),
        (str) => Papa.parse(str, { header: true })
      )(str),
    [onChange]
  );

  return <StyledCoreUploadButton onChange={handleChange} readAs="readAsText" />;
};

export default UploadButton;
