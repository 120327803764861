import styled from "styled-components";

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const ContainerInner = styled.div`
  display: grid;
  grid-template-columns: ${(p) => "250px 250px minmax(0, 1fr) 250px"};
  height: 100%;
  box-sizing: border-box;
`;

export { Container, ContainerInner };
