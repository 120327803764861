import styled from "styled-components";

const Container = styled.div`
  background: ${(p) => p.theme.color.softGray};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${(p) => p.theme.space.giant}px;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export { Container };
