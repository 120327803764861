import symbol from "assets/passbuy-symbol-black.svg";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  overflow: hidden;
  margin-bottom: ${(p) => p.theme.space.mini}px;
`;

const ContainerInner = styled.div`
  position: relative;
  left: -8px;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  height: 40px;
  width: auto;
`;

const Text = styled.div`
  font-family: ${(p) => p.theme.typography.heading};
  font-size: 24px;
  line-height: 0.95;
  color: ${(p) => p.theme.color.text.primary};
`;

const AppLogo = () => {
  return (
    <Container>
      <ContainerInner>
        <Image src={symbol} alt="logo" /> <Text>Pigma</Text>
      </ContainerInner>
    </Container>
  );
};

export default AppLogo;
