import Text from "components/Text";
import styled from "styled-components";

const Container = styled.div`
  width: ${(p) => p.width * p.zoom}mm;
  height: ${(p) => p.height * p.zoom}mm;
  padding: ${(p) => p.zoom * p.theme.space.mm.medium}mm;
  padding-bottom: 0;
  background: ${(p) =>
    p.color ||
    `radial-gradient(at top right, ${p.theme.color.purpleStreet}, ${p.theme.color.emeraldGreen} 50%) `};
  border-radius: ${(p) => p.zoom * p.theme.space.mm.medium}mm;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  align-self: stretch;
`;

const Location = styled(Text)`
  font-size: ${(p) => 4.7624999994 * p.zoom}mm;
  line-height: 1;
`;

export { Container, Content, Location };
