import AppLogo from "components/AppLogo";
import { map } from "ramda";
import React, { useMemo } from "react";
import Panel from "../Panel";

const Sidebar = ({ templates, onDownload }) => {
  const actions = useMemo(
    () => [
      {
        name: "Download",
        onClick: onDownload,
      },
    ],
    [onDownload]
  );

  const renderTemplates = map((t) => (
    <Panel.NavItem key={t.id} to={`templates/${t.id}`}>
      <Panel.ItemLabel>{t.name}</Panel.ItemLabel>
    </Panel.NavItem>
  ));

  const renderActions = map((a) => (
    <Panel.Item onClick={a.onClick} key={a.name} variant="success">
      <Panel.ItemLabel variant="success">{a.name}</Panel.ItemLabel>
    </Panel.Item>
  ));

  return (
    <Panel.Container>
      <Panel.Section>
        <AppLogo />
      </Panel.Section>

      <Panel.Section>
        <Panel.SectionLabel>Templates</Panel.SectionLabel>
        {renderTemplates(templates)}
      </Panel.Section>

      <Panel.Section>{renderActions(actions)}</Panel.Section>
    </Panel.Container>
  );
};

export default Sidebar;
