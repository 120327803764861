import { compose, map, path, take, toPairs } from "ramda";
import theme from "theme";
import Template from "../Template";
import CheckinCard from "./CheckinCard";

const checkinCardTemplate = Template({
  id: "card",
  name: "Card A7",
  component: CheckinCard,
  settingsSchema: [
    {
      id: "heading",
      heading: "Heading",
      type: "text",
      placeholder: "Heading...",
    },
    {
      id: "logo",
      heading: "Logo",
      type: "image",
    },
    {
      id: "logoSize",
      heading: "Logo size",
      type: "text",
    },
    {
      id: "backgroundColor",
      heading: "Background color",
      type: "color",
      options: compose(
        map(([key, value]) => ({
          label: key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (s) => s.toUpperCase()),
          value,
        })),
        take(5),
        toPairs,
        path(["color", "brandColors"])
      )(theme),
    },
    {
      id: "labelPrefix",
      heading: "Label prefix",
      type: "text",
      placeholder: "Prefix...",
    },
    {
      id: "textColor",
      heading: "Text color",
      type: "color",
      options: [
        { label: "White", value: theme.color.white },
        { label: "Black", value: theme.color.black },
      ],
    },
    {
      id: "layout",
      heading: "Layouts",
      type: "select",
      options: [{ label: "A4", value: "A7-ON-A4" }],
    },
    {
      id: "doubleSided",
      parent: "layout",
      parentValue: "A7-ON-A4",
      label: "Double sided",
      type: "boolean",
    },
    {
      id: "guidelines",
      parent: "layout",
      parentValue: "A7-ON-A4",
      label: "Guidelines",
      type: "boolean",
    },
  ],
});

export default checkinCardTemplate;
