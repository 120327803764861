import { PrintableHOC } from "components";
import LayoutHOC from "layouts/LayoutHoc";
import { compose, map } from "ramda";
import React from "react";
import styled from "styled-components";

const FlexWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const TemplateContainer = styled.div`
  margin-right: ${(p) => p.theme.space.large}px;
  margin-bottom: ${(p) => p.theme.space.large}px;
`;

const DefaultLayout = ({ children, registerPrintElement }) => {
  const renderChildren = map((child) => (
    <TemplateContainer key={child.key}>
      {React.cloneElement(child, {
        printRef: registerPrintElement(child.key),
      })}
    </TemplateContainer>
  ));

  return <FlexWrap>{renderChildren(children)}</FlexWrap>;
};

export default compose(PrintableHOC, LayoutHOC)(DefaultLayout);
