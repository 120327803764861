import { compose } from "ramda";
import React, { forwardRef } from "react";
import PrintableHOC from "../PrintableHOC";
import paperFormats from "./formats";
import { Container, Content } from "./Paper.styles";

const Paper = ({ format, children, zoom }, ref) => {
  if (!format) {
    return children || null;
  }

  return (
    <Container {...paperFormats[format].style} zoom={zoom}>
      <Content ref={ref}>
        {children && React.cloneElement(children, { zoom })}
      </Content>
    </Container>
  );
};

export default compose(PrintableHOC, forwardRef)(Paper);
