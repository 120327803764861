import A7OnA4Layout from "./A7OnA4Layout";
import DefaultLayout from "./DefaultLayout";

const layouts = [
  {
    id: "DEFAULT",
    name: "Grid",
    component: DefaultLayout,
  },
  {
    id: "A7-ON-A4",
    name: "A4",
    component: A7OnA4Layout,
  },
];

export default layouts;
