import { useTemplate } from "functions";
import {
  assoc,
  assocPath,
  compose,
  curry,
  defaultTo,
  find,
  map,
  prop,
  propEq
} from "ramda";
import React, { useEffect, useState } from "react";
import Panel from "../Panel";
import { Control } from "./controls";

const getSectionById = (schema, id) => find(propEq("id", id), schema);

const getParentById = compose(prop("parent"), getSectionById);

const SettingsPanel = ({ onChange, templates }) => {
  const { settingsSchema } = useTemplate(templates);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    setSettings({});
  }, [settingsSchema]);

  useEffect(() => {
    onChange(settings);
  }, [settings, onChange]);

  const handleChange = curry((sectionId, value) => {
    const parent = getParentById(settingsSchema, sectionId);
    const newValue = settings[sectionId] === value ? undefined : value;
    const updateFn = parent
      ? assocPath([`${parent}Settings`, sectionId], newValue)
      : assoc(sectionId, newValue);
    setSettings(updateFn);
  });

  const renderSchema = compose(
    map((s) => (
      <Control
        type={s.type}
        {...{
          section: s,
          settings,
          onChange: handleChange,
          key: s.id,
        }}
      />
    )),
    defaultTo([])
  );

  return <Panel.Container>{renderSchema(settingsSchema)}</Panel.Container>;
};

export default SettingsPanel;
