import { changeDpiDataUrl } from "changedpi";
import downloadjs from "downloadjs";
import { toPng } from "html-to-image";
import JSZip from "jszip";
import { compose, curry, isEmpty, map } from "ramda";
import base64ToDataUrl from "./base64-to-data-url";
import dataUrlToBase64 from "./data-url-to-base64";

const download = curry(async (filename, elements) => {
  if (isEmpty(elements)) {
    alert("Nothing to download");
    return;
  }

  const dataUrls = await compose(
    (ps) => Promise.all(ps),
    map((el) => toPng(el).then((dataUrl) => changeDpiDataUrl(dataUrl, 300)))
  )(elements);

  const zip = new JSZip();
  const folder = zip.folder(filename);

  dataUrls.forEach((dataUrl, i) =>
    folder.file(`${filename} (${i}).png`, dataUrlToBase64(dataUrl), {
      base64: true,
    })
  );

  const zipDataUrl = await zip.generateAsync({ type: "base64" });

  compose(
    (dataUrl) => downloadjs(dataUrl, filename),
    base64ToDataUrl("application/zip")
  )(zipDataUrl);
});

export default download;
