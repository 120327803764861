import passbuyLogo from "assets/passbuy-logo-white.svg";
import { Logotype, PrintableHOC, QRCode } from "components";
import { compose } from "ramda";
import React, { forwardRef } from "react";
import TemplateHOC from "../TemplateHoc";
import {
  Container,
  Main,
  Header,
  Heading,
  Footer,
} from "./CheckinCard.styles.js";

const CheckinCard = ({ model, settings, zoom }, ref) => {
  const { id, url } = model;
  const {
    backgroundColor,
    textColor,
    heading = "Betala notan",
    logo,
    logoSize = 1,
    labelPrefix,
  } = settings;
  const width = 74;
  const height = 105;
  const qrWidth = width * zoom * 0.580952381;
  const borderRadius = 1.0583333332;
  const logoHeight = 7.0308706955 * logoSize;
  const labelWithPrefix = `${labelPrefix} ${id}`;
  const label = labelPrefix ? labelWithPrefix : id;

  return (
    <Container {...{ ref, width, height, backgroundColor, zoom }}>
      <Header>
        <Heading {...{ zoom, color: textColor }}>{heading}</Heading>
      </Header>

      <Main>
        <QRCode
          data={url}
          width={qrWidth}
          zoom={zoom}
          borderRadius={borderRadius}
          label={label}
        />
      </Main>

      <Footer>
        <Logotype logo={logo || passbuyLogo} zoom={zoom} height={logoHeight} />
      </Footer>
    </Container>
  );
};

CheckinCard.defaultProps = {
  settings: {},
};

export default compose(PrintableHOC, TemplateHOC, forwardRef)(CheckinCard);
