import { compose, path } from "ramda";
import styled from "styled-components";
import ControlHoc from "./ControlHoc";

const Input = styled.input`
  padding: ${(p) => p.theme.space.small}px;
  border: 1px solid ${(p) => p.theme.color.border.primary};
  border-radius: ${(p) => p.theme.space.mini}px;
  width: 100%;
  box-sizing: border-box;
  font-family: ${(p) => p.theme.typography.text};
  font-size: 14px;

  &:focus-visible {
    outline: 1px solid ${(p) => p.theme.color.purpleStreet};
  }
`;

const Text = ({ onChange, section: { placeholder }, className }) => {
  const handleChange = compose(onChange, path(["target", "value"]));

  return <Input onChange={handleChange} placeholder={placeholder} className={className} />;
};

export default ControlHoc(Text);
