import React from "react";
import styled from "styled-components";

const GuidelineContainer = styled.div`
  position: relative;
`;

const Guideline = styled.div`
  background: ${(p) => p.theme.color.black};
  position: absolute;
  z-index: 999;

  ${(p) =>
    p.center &&
    `left: 50%;
        transform: translateX(-50%);`};
`;

const GuidelineHorizontal = styled(Guideline)`
  ${(p) => {
    const defaultWidth = p.theme.space.large * p.zoom;
    const widthCenter = defaultWidth * 2;
    const width = p.center ? widthCenter : defaultWidth;
    return `width: ${width}px;`;
  }}
  height: ${(p) => 1 * p.zoom}px;

  ${({ top, bottom, left, right, theme, center, margin = 0, zoom }) => {
    const thickness = 1 * zoom;
    const offset = thickness + margin;
    const defaultLength = theme.space.large * zoom;
    const centerLength = defaultLength * 2;
    const length = center ? centerLength : defaultLength;

    return `
        ${left && `left: -${length}px`};
        ${right && `right: -${length}px`};
        ${top && `top: -${offset}px`};
        ${bottom && `bottom: -${offset}px`};
    `;
  }}
`;

const GuidelineVertical = styled(Guideline)`
  height: ${(p) => p.theme.space.large * p.zoom}px;
  width: ${(p) => 1 * p.zoom}px;

  ${({ top, bottom, left, right, theme, margin = 0, zoom }) => {
    const thickness = 1 * zoom;
    const length = theme.space.large * zoom;
    const offset = length + margin;

    return `
        ${top && `top: -${offset}px`};
        ${bottom && `bottom: -${offset}px`};
        ${left && `left: -${thickness}px`};
        ${right && `right: -${thickness}px`};
    `;
  }}
`;

const PrintGuidelinesHOC = ({ children, enabled, ...props }) => {
  if (!enabled) {
    return children;
  }

  return (
    <GuidelineContainer>
      <GuidelineHorizontal {...props} top left />
      <GuidelineVertical {...props} top left />
      <GuidelineHorizontal {...props} top right />
      <GuidelineVertical {...props} top right />
      <GuidelineHorizontal {...props} bottom right />
      <GuidelineVertical {...props} bottom right />
      <GuidelineHorizontal {...props} bottom left />
      <GuidelineVertical {...props} bottom left />
      <GuidelineHorizontal {...props} top center />
      <GuidelineVertical {...props} top center />
      <GuidelineHorizontal {...props} bottom center />
      <GuidelineVertical {...props} bottom center />

      {children}
    </GuidelineContainer>
  );
};

export default PrintGuidelinesHOC;
