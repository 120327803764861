import { compose, head, path } from "ramda";
import styled from "styled-components";

const Container = styled.label`
  cursor: pointer;
  background-color: ${(p) => p.theme.color.dirtyWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.space.medium}px;
  border: 1px solid ${(p) => p.theme.color.border.primary};
  border-radius: ${(p) => p.theme.space.small}px;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: ${(p) => p.theme.color.softGray};
  }
`;

const Label = styled.div`
  font-family: ${(p) => p.theme.typography.text};
  font-size: 12.5px;
`;

const Input = styled.input`
  display: none;
`;

const UploadButton = ({ onChange, className, readAs }) => {
  const handleChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = compose(head, path(["target", "files"]))(e);
    reader.onloadend = () => onChange(reader.result);
    reader[readAs](file);
  };

  return (
    <Container className={className}>
      <Label>Upload</Label>
      <Input type="file" onChange={handleChange} />
    </Container>
  );
};

UploadButton.defaultProps = {
  readAs: "readAsDataURL",
};

export default UploadButton;
