import { compose, map, path, take, toPairs } from "ramda";
import theme from "theme";
import Template from "../Template";
import CheckinBadge from "./CheckinBadge";

const checkinBadgeTemplate = Template({
  id: "badge",
  name: "Badge 46x52mm",
  component: CheckinBadge,
  settingsSchema: [
    {
      id: "color",
      heading: "Color",
      type: "color",
      options: compose(
        map(([key, value]) => ({
          label: key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (s) => s.toUpperCase()),
          value,
        })),
        take(7),
        toPairs,
        path(["color", "brandColors"])
      )(theme),
    },
  ],
});

export default checkinBadgeTemplate;
