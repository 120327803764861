import { SettingsPanel } from "components";
import React from "react";
import { Route, Routes } from "react-router-dom";
import templates from "templates";

const SettingsView = ({ onChange }) => {
  return (
    <Routes>
      <Route
        path="/templates/:templateId"
        element={<SettingsPanel onChange={onChange} templates={templates} />}
      />
      <Route path="*" element={null} />
    </Routes>
  );
};

export default SettingsView;
