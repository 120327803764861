import { darken } from "polished";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styled from "styled-components";
import Text from "../Text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(p) => p.theme.space.large}px;
  ${(p) =>
    !p.right && `border-right: 1px solid ${p.theme.color.border.primary}`}
  ${(p) => p.right && `border-left: 1px solid ${p.theme.color.border.primary}`}
`;

const Section = styled.div`
  padding-bottom: ${(p) => p.theme.space.small}px;
  margin-bottom: ${(p) => p.theme.space.large}px;
  border-bottom: 1px solid ${(p) => p.theme.color.border.muted};

  ${(p) =>
    p.isChild &&
    ` border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  `}

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const SectionLabel = styled.div`
  font-family: ${(p) => p.theme.typography.heading};
  font-size: 16px;
  margin-bottom: ${(p) => p.theme.space.medium}px;
  user-select: none;
`;

const Item = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  padding: ${(p) => p.theme.space.small}px;
  margin-bottom: ${(p) =>
    p.border ? p.theme.space.small : p.theme.space.tiny}px;
  cursor: pointer;
  border-radius: ${(p) => p.theme.space.small}px;
  ${(p) =>
    `border: 1px solid ${
      p.selected || p.border ? p.theme.color.border.primary : "transparent"
    };`}
  ${(p) =>
    p.highlighted ? `border: 1px solid ${p.theme.color.purpleStreet}` : ""};
  ${(p) => p.selected && `background: ${p.theme.color.dirtyWhite}`};
  ${(p) =>
    p.variant === "success" && `background: ${p.theme.color.emeraldGreen}`};

  &:hover {
    background: ${(p) => p.theme.color.softGray};
    ${(p) =>
      p.variant === "success" &&
      `background: ${darken(0.05, p.theme.color.emeraldGreen)}`};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const NavItemLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  margin: -${(p) => p.theme.space.small}px;
  padding: ${(p) => p.theme.space.small}px;
`;

const NavItem = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Item selected={match}>
      <NavItemLink to={to} {...props}>
        {children}
      </NavItemLink>
    </Item>
  );
};

const ItemLabel = styled(Text)`
  font-size: 13.5px;
  line-height: 20px;
  font-family: ${(p) => p.theme.typography.text};
  color: ${(p) => p.theme.color.text.primary};
  ${(p) => p.variant === "success" && `color: ${p.theme.color.white}`};
  user-select: none;
`;

const Panel = { Container, Section, SectionLabel, Item, NavItem, ItemLabel };

export default Panel;
