import { PrintableHOC, QRCode } from "components";
import { compose } from "ramda";
import React, { forwardRef } from "react";
import TemplateHOC from "../TemplateHoc";

const CheckinQr = ({ model, zoom, settings }, ref) => {
  const { url } = model;
  const width = 60 * zoom * 0.7674418605;
  const { padding, isBorderRadius } = settings;
  const borderRadius = isBorderRadius ? padding : 0;

  return (
    <div key={url} ref={ref}>
      <QRCode data={url} {...{ width, zoom, padding, borderRadius }} />
    </div>
  );
};

export default compose(PrintableHOC, TemplateHOC, forwardRef)(CheckinQr);
