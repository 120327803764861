import { Grid, Paper, PrintableHOC, PrintGuidelines } from "components";
import LayoutHOC from "layouts/LayoutHoc";
import { compose, map, splitEvery } from "ramda";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const PaperContainer = styled.div`
  padding-bottom: ${(p) => p.theme.space.large}px;
`;

const A7onA4Layout = ({ children, settings, zoom, registerPrintElement }) => {
  const [groupedChildren, setGroupedChildren] = useState([]);
  const { doubleSided, guidelines } = settings.layoutSettings || {};

  useEffect(() => {
    compose(setGroupedChildren, splitEvery(4))(children);
  }, [children]);

  const renderChildren = map((child) => <>{child}</>);

  const renderPaper = ({ children, key, mirror }) => (
    <PaperContainer key={key}>
      <Paper format="a4" zoom={zoom} printRef={registerPrintElement(key)}>
        <PrintGuidelines enabled={guidelines} zoom={zoom}>
          <Grid rows={2} columns={2} rtl={mirror}>
            {renderChildren(children)}
          </Grid>
        </PrintGuidelines>
      </Paper>
    </PaperContainer>
  );

  const renderPaperBSide = ({ children, key }) =>
    renderPaper({
      children,
      key: `${key}-b-side`,
      mirror: true,
    });

  const renderContent = (children, i) => (
    <>
      {renderPaper({ children, key: i })}
      {doubleSided && renderPaperBSide({ children, key: i })}
    </>
  );

  return groupedChildren.map(renderContent);
};

export default compose(PrintableHOC, LayoutHOC)(A7onA4Layout);
