import Panel from "components/Panel";
import {
  all,
  compose,
  defaultTo,
  equals,
  includes,
  indexOf,
  is,
  isEmpty,
  map,
  of,
  slice,
  take,
  union,
  unless,
  without
} from "ramda";
import React, { useEffect, useState } from "react";
import UploadButton from "./UploadButton";

const Checkins = ({ onSelectedCheckinsChanged }) => {
  const [checkins, setCheckins] = useState([]);
  const [selectedCheckins, setSelectedCheckins] = useState([]);
  const isAllSelected = checkins.length === selectedCheckins.length;
  const [highlighted, setHighlighted] = useState();

  useEffect(() => {
    onSelectedCheckinsChanged(selectedCheckins);
  }, [selectedCheckins, onSelectedCheckinsChanged]);

  useEffect(() => {
    if (isEmpty(checkins)) {
      return;
    }

    setSelectedCheckins(take(4, checkins));
  }, [checkins, setSelectedCheckins]);

  const removeFromSelectedCheckins = compose(
    setSelectedCheckins,
    without,
    unless(is(Array), of)
  );

  const addToSelectedCheckins = compose(
    setSelectedCheckins,
    union,
    unless(is(Array), of)
  );

  const applySelection = (selection) => {
    const isAllSelected = all((e) => includes(e, selectedCheckins), selection);
    isAllSelected
      ? removeFromSelectedCheckins(selection)
      : addToSelectedCheckins(selection);
  };

  const handleCtrlClick = (c) =>
    includes(c, selectedCheckins)
      ? removeFromSelectedCheckins(c)
      : addToSelectedCheckins(c);

  const getSelection = (highlightedIndex, selectedIndex) => {
    const start = Math.min(highlightedIndex, selectedIndex) + 1;
    const end = Math.max(highlightedIndex, selectedIndex) + 1;
    return slice(start, end, checkins);
  };

  const handleShiftClick = (c) => {
    const selectedIndex = indexOf(c, checkins);
    const highlightedIndex = indexOf(highlighted, checkins);
    const selection = getSelection(highlightedIndex, selectedIndex);
    applySelection(selection);
  };

  const handleClick = (c) => (e) => {
    e.stopPropagation();
    setHighlighted(c);

    if (e.ctrlKey || e.metaKey) {
      handleCtrlClick(c);
      return;
    }

    if (e.shiftKey) {
      handleShiftClick(c);
      return;
    }

    setSelectedCheckins([c]);
  };

  const isSelected = (c) => includes(c, selectedCheckins) && !isAllSelected;

  const renderCheckin = (c) => (
    <Panel.Item
      onClick={handleClick(c)}
      selected={isSelected(c)}
      highlighted={equals(highlighted, c)}
      key={c.id}
      border
    >
      <Panel.ItemLabel>{c.id}</Panel.ItemLabel>
    </Panel.Item>
  );

  const renderAllButton = () => {
    const handleClick = () => setSelectedCheckins(checkins);

    return (
      <Panel.Item onClick={handleClick} selected={isAllSelected} border>
        <Panel.ItemLabel>All</Panel.ItemLabel>
      </Panel.Item>
    );
  };

  const renderCheckins = compose(map(renderCheckin), defaultTo([]));

  return (
    <Panel.Container right>
      <Panel.Section>
        <Panel.SectionLabel>Checkins</Panel.SectionLabel>

        <UploadButton onChange={setCheckins} />
      </Panel.Section>

      {!isEmpty(checkins) && renderAllButton()}
      {renderCheckins(checkins)}
    </Panel.Container>
  );
};

export default Checkins;
