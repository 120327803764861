import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(p) => `repeat(${p.columns || 2}, 1fr)`};
  grid-template-rows: ${(p) => `repeat(${p.rows || 2}, 1fr)`};
  column-gap: ${(p) => p.columnGap || 0};
  row-gap: ${(p) => p.columnGap || 0};
  ${(p) => p.rtl && "direction: rtl"};
`;

export default Grid;
