import Panel from "../../Panel";

const ControlHoc =
  (Component) =>
  ({ section, ...props }) => {
    const { parent, parentValue, id, heading } = section;

    if (parent && props.settings[parent] !== parentValue) {
      return null;
    }

    const mofifiedProps = {
      ...props,
      onChange: props.onChange(id),
      currentValue: props.settings[id],
    };

    return (
      <Panel.Section key={id} isChild={!!parent}>
        {heading && <Panel.SectionLabel>{heading}</Panel.SectionLabel>}
        <Component section={section} {...mofifiedProps} />
      </Panel.Section>
    );
  };

export default ControlHoc;
