import theme from "theme";
import Template from "../Template";
import CheckinQr from "./CheckinQr";

const checkinQrTemplate = Template({
  id: "checkin-qr",
  name: "Plain QR",
  component: CheckinQr,
  settingsSchema: [
    {
      id: "padding",
      heading: "Padding",
      type: "select",
      options: [
        { label: "None", value: 0 },
        {
          label: "Small",
          value: theme.space.small * 0.2645833333,
        },
        {
          label: "Medium",
          value: theme.space.medium * 0.2645833333,
        },
        {
          label: "Large",
          value: theme.space.large * 0.2645833333,
        },
      ],
    },
    {
      id: "isBorderRadius",
      type: "boolean",
      label: "Rounded corners",
    },
  ],
});

export default checkinQrTemplate;
