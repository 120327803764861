import React from "react";

const PrintableHOC = (Component) => {
  const WrappedComponent = (props) => {
    const modifiedProps = { ...props, ref: props.printRef };

    return React.createElement(Component, modifiedProps);
  };

  WrappedComponent.defaultProps = {
    registerPrintElement: () => {},
  };

  return WrappedComponent;
};

export default PrintableHOC;
