import React, { forwardRef } from "react";

const TemplateHOC = (Component) => {
  const WrappedComponent = (props, ref) => {
    const modifiedProps = { ...props, ref, zoom: props.zoom || 1 };
    return <Component {...modifiedProps} />;
  };

  WrappedComponent.defaultProps = {
    settings: {},
  };

  return forwardRef(WrappedComponent);
};

export default TemplateHOC;
