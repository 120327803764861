import { compose, curry, isNil, prop, reject, values } from "ramda";
import { useRef } from "react";
import download from "./download";

const downloadRefToArray = compose(reject(isNil), values, prop("current"));

const useDownolad = () => {
  const downloadRef = useRef({});

  const onDownload = () =>
    compose(download(`Passbuy QR`), downloadRefToArray)(downloadRef);

  const addToDownload = curry((id, ref) => (downloadRef.current[id] = ref));

  return [addToDownload, onDownload];
};

export default useDownolad;
