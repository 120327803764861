import { useTemplate } from "functions";
import { Layout } from "layouts";
import { map } from "ramda";
import React from "react";
import templates from "templates";
import { Container } from "./Preview.styles";

const Preview = ({ data, registerPrintElement, settings, zoom }) => {
  const template = useTemplate(templates);
  const { layout: layoutId } = settings;
  const previewProps = { settings, zoom };
  const layoutProps = { layoutId, settings, zoom, registerPrintElement };

  const renderTemplates = map((model) => (
    <template.component {...{ ...previewProps, model, key: model.id }} />
  ));

  if (!template) {
    return null;
  }

  return (
    <Container>
      <Layout {...layoutProps}>{renderTemplates(data)}</Layout>
    </Container>
  );
};

export default Preview;
