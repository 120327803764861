import React from "react";
import styled from "styled-components";

const Image = styled.img`
  height: ${(p) => p.height * p.zoom}mm;
  width: auto;
  position: relative;
  left: -0.2645833333mm;
`;

const Logotype = ({ logo, ...props }) => {
  return <Image src={logo} alt="logo" {...props} />;
};

export default Logotype;
