import Preview from "preview";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const PreviewView = ({ defualtTemplate, ...props }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to={`templates/${defualtTemplate}`} replace />}
      />
      <Route path="/templates/:templateId" element={<Preview {...props} />} />
    </Routes>
  );
};

export default PreviewView;
