import Panel from "components/Panel";
import { useState } from "react";
import styled from "styled-components";
import ControlHoc from "./ControlHoc";

const Radio = styled.input`
  margin: 0;
  margin-right: ${(p) => p.theme.space.small}px;
`;

const Select = ({ section, onChange }) => {
  const [value, setValue] = useState(false);

  const handleChange = () =>
    setValue((v) => {
      const newValue = !v;
      onChange(newValue);
      return newValue;
    });

  return (
    <label>
      <Panel.Item>
        <Radio type="checkbox" checked={value} onChange={handleChange} />
        <Panel.ItemLabel>{section.label}</Panel.ItemLabel>
      </Panel.Item>
    </label>
  );
};

export default ControlHoc(Select);
