import { compose, defaultTo, find, propEq } from "ramda";
import React, { useEffect, useState } from "react";
import layouts from "./layouts";

const Layout = ({ layoutId, children, ...props }) => {
  const [layout, setLayout] = useState(layouts[0]);

  useEffect(() => {
    compose(
      setLayout,
      defaultTo(layouts[0]),
      find(propEq("id", layoutId))
    )(layouts);
  }, [layoutId]);

  return <layout.component {...props}>{children}</layout.component>;
};

export default Layout;
