import { compose, map, prop } from "ramda";
import Panel from "../../Panel";
import ControlHoc from "./ControlHoc";

const Select = ({ onChange, section, currentValue }) => {
  const renderOption = ({ value, label }) => (
    <Panel.Item
      onClick={() => onChange(value)}
      key={label}
      selected={value === currentValue}
    >
      <Panel.ItemLabel>{label}</Panel.ItemLabel>
    </Panel.Item>
  );

  return compose(map(renderOption), prop("options"))(section);
};

export default ControlHoc(Select);
