import { always, compose, cond, equals, T } from "ramda";
import Bool from "./Boolean";
import Color from "./Color";
import Img from "./Image";
import Select from "./Select";
import Text from "./Text";

const Control = ({ type, ...props }) =>
  compose(
    (Component) => Component && <Component {...props} />,
    cond([
      [equals("select"), always(Select)],
      [equals("color"), always(Color)],
      [equals("text"), always(Text)],
      [equals("boolean"), always(Bool)],
      [equals("image"), always(Img)],
      [T, always(null)],
    ])
  )(type);

export default Control;
