import passbuyLogo from "assets/passbuy-logo-white.svg";
import { Logotype, PrintableHOC, QRCode } from "components";
import { memo } from "functions";
import { allPass, compose, eqBy, eqProps, path } from "ramda";
import React, { forwardRef } from "react";
import TemplateHOC from "../TemplateHoc";
import { Container, Content, Location } from "./CheckinBadge.styles";

const CheckinBadge = ({ model, zoom, settings }, ref) => {
  const { id, url } = model;
  const { color } = settings;
  const width = 46;
  const height = 52;
  const qrWidth = width * zoom * 0.7674418605;
  const logoHeight = 5.8208333326;

  return (
    <Container key={url} {...{ width, height, ref, zoom, color }}>
      <QRCode data={url} width={qrWidth} zoom={zoom} />

      <Content>
        <Logotype logo={passbuyLogo} height={logoHeight} zoom={zoom} />
        <Location zoom={zoom}>{id}</Location>
      </Content>
    </Container>
  );
};

export default compose(
  memo(
    allPass([
      (p, n) => eqBy(path(["model", "id"]), p, n),
      (p, n) => eqProps("zoom", p, n),
      (p, n) => eqProps("settings", p, n),
    ])
  ),
  PrintableHOC,
  TemplateHOC,
  forwardRef
)(CheckinBadge);
