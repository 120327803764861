import { Text } from "components";
import styled from "styled-components";

const Container = styled.div`
  width: ${(p) => p.width * p.zoom}mm;
  height: ${(p) => p.height * p.zoom}mm;
  background-image: ${(p) => `
    radial-gradient(at bottom left, ${p.theme.color.purpleStreet}, ${p.theme.color.emeraldGreen} 50%)
  `};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;

  ${(p) => p.backgroundColor && `background: ${p.backgroundColor}`};
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.header`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Heading = styled(Text)`
  max-width: 100%;
  font-size: ${(p) => 7.0308706955 * p.zoom}mm;
  text-align: center;
  padding: 0 ${(p) => p.theme.space.large}px;
  color: ${(p) => p.color || p.theme.color.white};
`;

const Footer = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Container, Main, Header, Heading, Footer };
