import styled from "styled-components";

const Container = styled.div`
  border: 1px solid ${(p) => p.theme.color.border.primary};
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  width: ${(p) => p.width * p.zoom}px;
  height: ${(p) => p.height * p.zoom}px;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
`;

export { Container, Content };
