import { Checkins, Sidebar } from "components";
import OffScreenArea from "components/OffScreenArea";
import { useDownload } from "functions";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Styles from "styles";
import templates from "templates";
import theme from "theme";
import { PreviewView, SettingsView } from "views";
import { Container, ContainerInner } from "./App.styles";

const App = () => {
  const [data, setData] = useState([]);
  const [settings, setSettings] = useState({});
  const [addToDownload, download] = useDownload();
  const [isPrintMode, setIsPrintMode] = useState(false);
  const previewPros = { data, settings, defualtTemplate: templates[0].id };
  const printProps = {
    ...previewPros,
    zoom: 3.1249436937, // adjusting for conversion from 72dpi in mm to 300dpi in px
    registerPrintElement: addToDownload,
  };

  const handleDownload = () => {
    setIsPrintMode(true);
    setTimeout(() => download(), 1000);
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Styles />

        <Container>
          <ContainerInner>
            <Sidebar templates={templates} onDownload={handleDownload} />

            <SettingsView onChange={setSettings} />

            <PreviewView {...previewPros} />

            {/* Print */}
            {isPrintMode && (
              <OffScreenArea>
                <PreviewView {...printProps} />
              </OffScreenArea>
            )}

            <Checkins onSelectedCheckinsChanged={setData} />
          </ContainerInner>
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
