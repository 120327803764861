const paperFormats = {
  a0: {
    id: "a0",
    name: "A0",
    style: {
      width: 9933 * 0.3203661327,
      height: 14043 * 0.3203661327,
    },
  },
  a1: {
    id: "a1",
    name: "A1",
    style: {
      width: 7016 * 0.3203661327,
      height: 9933 * 0.3203661327,
    },
  },
  a2: {
    id: "a2",
    name: "A2",
    style: {
      width: 4961 * 0.3203661327,
      height: 7016 * 0.3203661327,
    },
  },
  a3: {
    id: "a3",
    name: "A3",
    style: {
      width: 3508 * 0.3203661327,
      height: 4961 * 0.3203661327,
    },
  },
  a4: {
    id: "a4",
    name: "A4",
    style: {
      width: 2480 * 0.3203661327,
      height: 3508 * 0.3203661327,
    },
  },
  a5: {
    id: "a5",
    name: "A5",
    style: {
      width: 1748 * 0.3203661327,
      height: 2480 * 0.3203661327,
    },
  },
  a6: {
    id: "a6",
    name: "A6",
    style: {
      width: 1240 * 0.3203661327,
      height: 1748 * 0.3203661327,
    },
  },
  a7: {
    id: "a7",
    name: "A7",
    style: {
      width: 874 * 0.3203661327,
      height: 1240 * 0.3203661327,
    },
  },
  a8: {
    id: "a8",
    name: "A8",
    style: {
      width: 614 * 0.3203661327,
      height: 874 * 0.3203661327,
    },
  },
};

export default paperFormats;
