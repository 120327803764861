import { PrintableHOC } from "components";
import React from "react";
import styled from "styled-components";
import theme from "theme";
import Text from "../Text";
import Data from "./Data";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: ${(p) => p.zoom * p.padding}mm;
  border-radius: ${(p) => p.zoom * p.borderRadius}mm;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-top: ${(p) => p.zoom * p.theme.space.mm.small}mm;
`;

const Label = styled(Text)`
  color: ${(p) => p.theme.color.text.primary};
  line-height: 1;
  font-size: ${(p) => 3.9687499995 * p.zoom}mm;
`;

const QRCode = ({ data, width, zoom, padding, borderRadius, label }) => {
  return (
    <Container {...{ zoom, borderRadius, width, padding }}>
      <Data {...{ data, width }} />

      {label && (
        <LabelContainer zoom={zoom}>
          <Label zoom={zoom}>{label}</Label>
        </LabelContainer>
      )}
    </Container>
  );
};

QRCode.defaultProps = {
  borderRadius: theme.space.mm.small,
  padding: theme.space.mm.small,
};

export default PrintableHOC(QRCode);
