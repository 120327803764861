import { hexString } from "functions";
import { compose, curry, map, prop } from "ramda";
import styled from "styled-components";
import Panel from "../../Panel";
import ControlHoc from "./ControlHoc";
import Text from "./Text";

const ItemInner = styled.div`
  display: flex;
  align-items: center;
`;

const ColorBadge = styled.div`
  border-radius: ${(p) => p.theme.space.huge}px;
  height: ${(p) => p.theme.space.huge}px;
  width: ${(p) => p.theme.space.huge}px;
  margin-right: ${(p) => p.theme.space.medium}px;
  background: ${(p) => p.color};
  ${(p) =>
    p.color === "#FFFFFF" &&
    `border: 1px solid ${p.theme.color.border.primary};`}
`;

const CustomColorText = styled(Text)`
  margin-top: 12px;
`;

const Color = ({ settings, onChange, section, currentValue }) => {
  const renderColor = ({ label, value }) => (
    <Panel.Item
      key={label}
      onClick={() => onChange(value)}
      selected={value === currentValue}
    >
      <ItemInner>
        <ColorBadge color={value} />
        <Panel.ItemLabel>{label}</Panel.ItemLabel>
      </ItemInner>
    </Panel.Item>
  );

  const renderOptions = compose(map(renderColor), prop("options"));

  const handleChange = curry((ignore, value) =>
    compose(onChange, hexString)(value)
  );

  return (
    <>
      {renderOptions(section)}

      <CustomColorText
        onChange={handleChange}
        settings={settings}
        section={{ ...section, heading: false }}
      />
    </>
  );
};

export default ControlHoc(Color);
